import React from "react"
import * as THREE from "three"
import SeoTitle from "../../components/seo"
import gsap from "gsap"
import {
  PageTitle,
  AppWrapper,
  Stage,
  Connect,
  GridStage,
  SubDescription,
  SubStage,
  Split,
  Section,
} from "./styles"
import TopNav from "../top-nav"
import { navigate } from "gatsby"
import styled from "styled-components"

const objectsDistance = 10

const Link = styled.a`
  cursor: pointer;
`
const SkySplashContent = () => {
  const ref = React.useRef(null)
  const sectionRef = {
    intro: React.useRef(null),
  }
  const onMount = React.useCallback(() => {
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    }
    const target = []
    const scene = new THREE.Scene()

    const textureLoader = new THREE.TextureLoader()
    const cloudTexture1 = textureLoader.load("/assets/landing/MainCloud.png")
    const cloudTexture2 = textureLoader.load("/assets/landing/Cloud2.png")
    const skyTexture = textureLoader.load("/assets/landing/sky.png")

    const renderer = new THREE.WebGLRenderer()
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    renderer.setClearColor(new THREE.Color(0xffffff))

    ref.current.appendChild(renderer.domElement)

    const skyMesh = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1, 1),
      new THREE.MeshBasicMaterial({
        map: skyTexture,
      })
    )
    skyMesh.position.z = -10
    scene.add(skyMesh)
    const cloudGroup = new THREE.Group()
    scene.add(cloudGroup)

    function addClouds(index) {
      const cloudTextures = [cloudTexture1, cloudTexture2]
      const textureMap = cloudTextures[Math.floor(Math.random())]
      const cluster = new THREE.Group()
      scene.add(cluster)

      const baseScale = sizes.width / 740

      cluster.position.y = Math.sin((Math.random() * 180) / Math.PI)
      cluster.position.x = objectsDistance * index

      for (let cloudIndex = 0; cloudIndex < 5; cloudIndex++) {
        let randomScale = Math.random() + baseScale > 1.5 ? 1.5 : baseScale * 2
        let planeMesh = new THREE.Mesh(
          new THREE.PlaneGeometry(1, 0.75, 1),
          new THREE.MeshBasicMaterial({
            map: textureMap,
            transparent: true,
          })
        )
        if (index === 0 && cloudIndex === 0) {
          target.push(planeMesh)
        }
        cluster.add(planeMesh)
        const distribution = {
          x: 0.5 * Math.sin((Math.random() * 360) / (2 * Math.PI)),
          y: Math.sin((Math.random() * 360) / (2 * Math.PI)),
        }
        const isLandscape = sizes.width > sizes.height
        planeMesh.position.y = isLandscape ? distribution.x : distribution.y
        planeMesh.position.x = isLandscape ? distribution.y : distribution.x
        planeMesh.position.z = Math.random()
        planeMesh.scale.set(randomScale, randomScale, randomScale)
        cluster.add(planeMesh)
      }
      return cluster
    }

    const sectionMeshes = []

    for (let cloudIndex = 0; cloudIndex < 5; cloudIndex++) {
      const cloudCluster = addClouds(cloudIndex)
      sectionMeshes.push(cloudCluster)
      cloudGroup.add(cloudCluster)
    }

    /**
     * Camera
     */
    const cameraGroup = new THREE.Group()
    scene.add(cameraGroup)

    // Base camera
    const camera = new THREE.PerspectiveCamera(
      35,
      sizes.width / sizes.height,
      0.1,
      100
    )
    camera.position.z = 6
    cameraGroup.add(camera)

    function updateSkyDimension() {
      const cameraAngle = (camera.fov * Math.PI) / 180
      const fov_y = camera.position.z * Math.tan(cameraAngle / 2) * 6
      skyMesh.scale.set(fov_y * camera.aspect, fov_y, 1)
    }
    updateSkyDimension()

    window.addEventListener("resize", () => {
      // Update sizes
      sizes.width = window.innerWidth
      sizes.height = window.innerHeight

      // Update camera
      camera.aspect = sizes.width / sizes.height
      camera.updateProjectionMatrix()

      updateSkyDimension()

      // Update renderer
      renderer.setSize(sizes.width, sizes.height)
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })
    /**
     * Scroll
     */

    let scrollY = window.scrollY
    let currentSection = 0
    window.addEventListener("scroll", () => {
      scrollY = window.scrollY

      const newSection = Math.round(scrollY / sizes.height)
      if (newSection !== currentSection) {
        currentSection = newSection
        sectionMeshes[currentSection].children.forEach(child => {
          gsap.to(child.position, {
            duration: 1.5,
            ease: "power2.inOut",
            x: `+=${Math.sin((Math.random() * 180) / Math.PI) * 0.5}`,
            y: `+=${Math.sin((Math.random() * 180) / Math.PI) * 0.5}`,
          })
        })
      }
    })

    const cursor = {}
    cursor.x = 0
    cursor.y = 0

    window.addEventListener("mousemove", event => {
      cursor.x = event.clientX / sizes.width - 0.5
      cursor.y = event.clientY / sizes.height - 0.5
    })

    /**
     * Animate
     */
    const clock = new THREE.Clock()
    let previousTime = 0

    const tick = () => {
      const elapsedTime = clock.getElapsedTime()
      const deltaTime = elapsedTime - previousTime
      previousTime = elapsedTime

      //   Animate camera
      camera.position.x = -(scrollY * -objectsDistance) / sizes.height
      skyMesh.position.x = -(scrollY * -objectsDistance) / sizes.height

      const parallaxX = cursor.x
      const parallaxY = -cursor.y
      cameraGroup.position.x +=
        (parallaxX - cameraGroup.position.x) * 0.5 * deltaTime
      cameraGroup.position.y =
        (parallaxY - cameraGroup.position.y) * 2 * deltaTime

      // Render
      renderer.render(scene, camera)

      // Call tick again on the next frame
      window.requestAnimationFrame(tick)
    }

    tick()
  }, [])

  React.useEffect(() => {
    onMount()
  }, [onMount])

  return (
    <AppWrapper>
      <Section>
        <div>
          <PageTitle id="top_t1" sizeQuery={70}>
            Frances NG
          </PageTitle>
          <h2>Software Engineer</h2>
          <SubDescription>"Just Enjoy"</SubDescription>
          <div>- - -</div>
          <div>scroll down ↓</div>
          <div>
            or
            <Link onClick={() => navigate("/blogs")}>read chatter ↗</Link>
          </div>
        </div>
      </Section>
      <Section ref={sectionRef.intro} flexDirection="column">
        <PageTitle size="60px" padding="0" sizeQuery={55}>
          Briefly and Shortly
        </PageTitle>
        <Split>
          <div>
            <Stage>
              MEng in Aeronautics & Astronautics then worked as Aircraft
              Engineer
            </Stage>
            <Connect />
            <Stage>Jumped out of Aviation to code my mind out</Stage>
            <Connect />
            <GridStage>
              <SubStage>Passion in coding and design</SubStage>
              <SubStage size={50}>+</SubStage>
              <SubStage>Build and Create for good</SubStage>
            </GridStage>
          </div>
          <div>
            <h3>Currently</h3>
            <Stage>Freelancing + Pursuing an animation dream</Stage>
            <h3>Previously at</h3>
            <Stage>
              Bowtie Life Insurance (Hong Kong)
              <br />
              Datarama (Singapore) <br />
              Cogent Labs (Tokyo)
            </Stage>
          </div>
        </Split>
      </Section>
      <Section>
        <div>
          <PageTitle>Contact</PageTitle>
          <a
            href="mailto:franxyzxyz@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Email
          </a>
          <a
            href="https://twitter.com/franxyzxyz"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://github.com/franxyzxyz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github
          </a>
          <a
            href="https://hk.linkedin.com/in/francesng/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
          <a
            href="https://medium.com/@franxyzxyz"
            target="_blank"
            rel="noopener noreferrer"
          >
            Medium
          </a>
        </div>
      </Section>
      <div
        ref={ref}
        style={{ zIndex: -1, position: "fixed", top: 0, left: 0 }}
      ></div>
    </AppWrapper>
  )
}
const SkySplash = ({ location }) => {
  return (
    <>
      <SeoTitle title={`"Just Enjoy"`} />
      <TopNav location={location} theme="dark" />
      <SkySplashContent />
    </>
  )
}
export default SkySplash
